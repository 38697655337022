<template>
    <div class="gif-picker-parent">
        <div class="gif-invoker" @click="toggle">
            <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <text x="50%" y="50%" text-anchor="middle"
                    alignment-baseline="middle" font-size="14"
                    font-weight="bold" stroke="none" fill="#d6d8db">
                    GIF
                </text>
            </svg>
        </div>
        <div
        class="gif-picker is-fullwidth"
            v-if="showGif">
            <div class="control is-fullwidth">
                <input class="input input is-fullwidth"
                    type="text"
                    @input="onInput"
                    v-model="keyword"
                    :placeholder="i18n('Search') ">
            </div>
        <div>
            <div class="gifs">
                <gif v-for="gif in gifs" :key="gif.id" :gif="gif" @click="insert(gif)"/>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import Gif from './Gif.vue';

export default {
    name: 'GifList',
    components: {
        Gif,
    },
    inject: ['i18n'],
    props: {
        gifs: {
            type: Array,
            required: true,
        },
        showGif: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    emits: ['fetch-gifs', 'send-gif', 'gif-state'],
    data() {
        return {
            keyword: '',
            timeout: null,
            display: {
                visible: false,
                emojiVis: false,
            },
            gifUrl: '',
        };
    },
    mounted() {
        this.search();
    },

    methods: {
        insert(gif) {
            this.gifUrl = gif.images.original.url;
            this.$emit('send-gif', this.gifUrl);
        },
        toggle() {
            this.$emit('gif-state', !this.showGif, this.index);
        },
        onInput() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.search();
            }, 500);
        },
        search() {
            const searchKeyWord = this.keyword ? this.keyword : 'congrats';
            fetch(`https://api.giphy.com/v1/gifs/search?api_key=u3WsXpEZ1pBTOz34D8MOEd5TCmHIERcV&q=${searchKeyWord}&limit=100`)
                .then(response => response.json())
                .then(result => {
                    this.$emit('fetch-gifs', result.data);
                });
        },
    },
};

</script>
<style lang="scss">
@import '../../../sass/enso.scss';
.gifs {
    padding: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    cursor: pointer;
}
.gif-invoker {
    position: absolute;
    top: 0.5rem;
    right: 2.7rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
}
.gif-picker-parent{
    position: relative;
    top: -3.5rem;
    display: flex;
    justify-content: flex-end;
    .gif-picker {
        position: absolute;
        top: 0;
        width: 30rem;
        height: 20rem;
        max-width: 100%;
        z-index: 1;
        font-family: Montserrat;
        border: 1px solid $mediumLightGrey;
        overflow: auto;
        padding: 1rem;
        box-sizing: border-box;
        border-radius: .5rem;
        background: $secondary;
        box-shadow: 1px 1px 8px $mediumLightGrey;
        right: 0;
        transform: translateY(-100%);
    }
}
.gif-picker__search {
  display: flex;
}

.gif-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid $mediumLightGrey;
  padding: .5rem 1rem;
  outline: none;
}
</style>
