<template>
    <div class="emoji-picker-parent">
      <div class="emoji-invoker" @click="toggle">
        <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99
             10C17.52 22 22 17.52 22 12S17.52 2 11.99
             2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8
              3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67
               1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67
                1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33
                 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5
                  6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"/>
        </svg>
      </div>
      <div
        class="emoji-picker"
        ref="emojiPicker"
        v-if="showEmoji">
        <div class="emoji-picker__search field has-addons">
            <div class="control">
                <input class="input"
                    type="text"
                    v-model="search"
                    ref="search"
                    :placeholder="i18n('Search')">
            </div>
        </div>
        <div>
          <div v-for="(emojis, category) in searchedEmojis" :key="category">
            <h5>
                {{ i18n(category) }}
            </h5>
            <div class="emojis">
              <span v-for="(emoji, emojiName) in emojis" :key="emojiName"
                @click="insert(emoji)"
                :title="emojiName">
                {{ i18n(emoji) }}
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
<script>
import emojisData from './emojis-data.json';

export default {
    name: 'EmojiPlcker',
    directives: {
        'click-outside': {
            bind(el, binding) {
                if (typeof binding.value !== 'function') {
                    return;
                }
                const { bubble } = binding.modifiers;
                const handler = e => {
                    if (bubble || (!el.contains(e.target) && el !== e.target)) {
                        binding.value(e);
                    }
                };
                el.__vueClickOutside__ = handler;

                document.addEventListener('click', handler);
            },
            unbind(el) {
                document.removeEventListener('click', el.__vueClickOutside__);

                el.__vueClickOutside__ = null;
            },
        },
    },
    inject: ['http', 'route', 'i18n'],
    props: {
        showEmoji: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    emits: ['emoji', 'emoji-send', 'emoji-actual', 'show-emoji', 'emoji-state'],
    data() {
        return {

            emojis: emojisData,
            search: '',
            display: {
                x: 0,
                y: 0,
                visibleEmoji: false,
                visibleGif: false,
            },
        };
    },

    computed: {
        searchedEmojis() {
            if (this.search) {
                const obj = {};

                Object.keys(this.emojis).forEach(category => {
                    obj[category] = {};

                    Object.keys(this.emojis[category]).filter(emoji => new RegExp(`.*${this.search}.*`).test(emoji)).forEach(emoji => {
                        obj[category][emoji] = this.emojis[category][emoji];
                    });
                });
                return obj;
            }
            return this.emojis;
        },
    },
    methods: {
        insert(emoji) {
            this.$emit('emoji', emoji);
        },
        toggle() {
            this.$emit('emoji-state', !this.showEmoji, this.index);
        },
        hide() {
            this.display.visibleEmoji = false;
        },
    },
    template: '#emoji-picker',
};
</script>

<style lang="scss">
@import '../../../sass/enso.scss';
.emoji-invoker {
  position: absolute;
  top: .5rem;
  right: 1.1rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all .2s;
}

.emoji-invoker:hover {
  transform: scale(1.1);
}

.emoji-invoker > svg {
  fill: $mediumLightGrey;
}

.emoji-picker-parent {
    position: relative;
    top: -3.5rem;
    display: flex;
    justify-content: flex-end;
    .emoji-picker{
        position: absolute;
        top: 0;
        z-index: 999;
        font-family: Montserrat;
        border: 1px solid $mediumLightGrey;
        width: 15rem;
        height: 20rem;
        max-width: 100%;
        overflow-y: auto;
        padding: 1rem;
        box-sizing: border-box;
        border-radius: .5rem;
        background: $secondary;
        box-shadow: 1px 1px 8px $mediumLightGrey;
        right: 0;
        transform: translateY(-100%);
    }

}

.emoji-picker__search {
  display: flex;
}

.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid $mediumLightGrey;
  padding: .5rem 1rem;
  outline: none;
}

h5 {
  margin: 5px !important;
  color: $darkGrey;
  text-transform: uppercase;
  font-size: 0.6rem !important;
  cursor: default;
}

.emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.emojis:after {
  content: "";
  flex: auto;
}

span {
  padding: .2rem;
  cursor: pointer;
  border-radius: 5px;
}

span:hover {
  background: $lightGrey;
  cursor: pointer;
}
</style>
