<template>
      <div class="sizeGif">
        <img :src="gif.images.original.url" alt="Gif Image">
      </div>
</template>

<script>

export default {
    name: 'Gif',
    props: {
        gif: {
            type: Object,
            required: true,
        },
    },
};

</script>
<style>
.sizeGif {
  margin: 7px;
    width: 190px;
}
</style>
